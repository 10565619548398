import {EvmAddress}       from "@resources/@types/common/type";
import {splittingAddress} from "@utils/Format";
import {switchAccount}    from "@wagmi/core";
import {
  useCallback,
  useEffect,
  useState
}                         from "react";
import {
  useAccount,
  useDisconnect
}                         from "wagmi";



const useEvmAccount = () => {
  const {
          address,
          isConnecting,
          addresses,
          chainId,
          chain,
          isConnected,
          isDisconnected,
          connector,
        } = useAccount()

  const {disconnect} = useDisconnect()

  const [hasAddress, setHasAddress]     = useState(false)
  const [splitAddress, setSplitAddress] = useState<EvmAddress>()


  const _setAddresses = useCallback((address?: EvmAddress) => {
    const {data: _parsedAddress, success, error} = EvmAddress.safeParse(address)

    if (!_parsedAddress || !success || error) {
      setHasAddress(false)
      setSplitAddress(undefined)

      return;
    }

    const _splitAddress = splittingAddress(_parsedAddress) as EvmAddress

    setHasAddress(!!_splitAddress)
    setSplitAddress(_splitAddress)
  }, [address])

  useEffect(() => {
    _setAddresses(address)
  }, [_setAddresses]);

  useEffect(() => {
    if (isDisconnected) {
      _setAddresses()
      disconnect()
    }
  }, [isDisconnected]);



  return {
    hasAddress,
    address,
    splitAddress,
    disconnect,
    isConnecting,
    addresses,
    chainId,
    chain,
    isConnected,
    isDisconnected,
    connector,
  }
}

export default useEvmAccount;
