import type {
  BridgesByChain,
  ContractInfo,
  TokenPairsByChain,
  TokensByChain
} from "@resources/@types/common/type";

import {
  arbitrumOne,
  arbitrumOneBridges,
  arbitrumOneNftContract,
  arbitrumOneSwapPair,
  arbitrumOneTokens,
  arbitrumOneVestingContract
}                    from "@resources/config/contracts/arbitrum";
import {
  havah,
  havahBridges,
  havahNftContract,
  havahTokens,
}                    from "@resources/config/contracts/havah";
import {
  zkSyncEra,
  zkSyncEraBridges,
  zkSyncEraNftContract,
  zkSyncEraSwapPair,
  zkSyncEraTokens
}                    from "@resources/config/contracts/zkSync";
import {parse}       from "@utils/JSON";
import {getImageUrl} from "@utils/Utils";
import {
  type Chain,
}                    from "wagmi/chains";


// 하바 체인 넣지 말것. (리스트 목록에 필요시 필요한 컴포넌트에서 수동 push)
const chains:[Chain, ...Chain[]] = [
  // zkSyncEra,
  arbitrumOne,
] as const;

// const chains = [
//   // zkSyncEra,
//   arbitrumOne,
// ];

const chainLogos = {
  [arbitrumOne.id]: getImageUrl("src/assets/images/logo/chain/arbitrum-one-logo.svg"),
  // [zkSyncEra.id]  : getPublicImgUrl("images/logo/chain/zk-sync-era-logo.svg"),
}

const defaultChain = arbitrumOne


const tokensByChain: TokensByChain = {
  [arbitrumOne.id]: (
    parse(import.meta.env.VITE_IS_INNER_NETWORK)
      ? Object.values(arbitrumOneTokens) as ContractInfo[]
      : Object.values(arbitrumOneTokens).filter(value => value.id !== arbitrumOneTokens.ARB_TOKEN.id) as ContractInfo[]
  ),
  [zkSyncEra.id]  : Object.values(zkSyncEraTokens) as ContractInfo[],
  [havah.id]      : (
    parse(import.meta.env.VITE_IS_INNER_NETWORK)
      ? Object.values(havahTokens) as ContractInfo[]
      : Object.values(havahTokens).filter(value => value.id !== havahTokens.ARB_TOKEN.id) as ContractInfo[]
  ),
} as const

const tokenPairsByChain: TokenPairsByChain = {
  [arbitrumOne.id]: arbitrumOneSwapPair,
  [zkSyncEra.id]  : zkSyncEraSwapPair,
} as const

const bridgesByChain: BridgesByChain = {
  [arbitrumOne.id]: arbitrumOneBridges,
  [zkSyncEra.id]  : zkSyncEraBridges,
} as const

export {
  chains,
  chainLogos,
  defaultChain,
  tokensByChain,
  tokenPairsByChain,
  bridgesByChain,
  // Arbitrum
  arbitrumOne,
  arbitrumOneBridges,
  arbitrumOneSwapPair,
  arbitrumOneTokens,
  arbitrumOneNftContract,
  arbitrumOneVestingContract,
  // ZkSync
  zkSyncEra,
  zkSyncEraBridges,
  zkSyncEraSwapPair,
  zkSyncEraTokens,
  zkSyncEraNftContract,
  // Havah
  havah,
  havahTokens,
  havahNftContract,
  havahBridges,
}
