import {wagmiConfig}             from "@components/container/WagmiReactQueryContainer";
import {TOAST_TYPE}              from "@resources/@types/toast/toast";
import {chains}                  from "@resources/config/contracts";
import {useCommonSourceChain}    from "@stores/common/store";
import {useFtActions} from "@stores/fungible-token/store";
import {useNftActions}           from "@stores/nft/store";
import {watchAccount}            from "@wagmi/core";
import {
  useEffect,
  useLayoutEffect
} from "react";
import useHavahAccount           from "./useHavahAccount";
import useEvmAccount             from "./useEvmAccount";
import useToastControl           from "./useToastControl";

const useWatchAccountChange = () => {
  const {onToast}                          = useToastControl()
  const {address: evmAddress, isConnected} = useEvmAccount()
  const {address: havahAddress, open}      = useHavahAccount()
  const {sourceChain: {id: sourceChainId}} = useCommonSourceChain()
  const {updateFtTargetTokenAmount}        = useFtActions()
  const {resetNftTargetToken}              = useNftActions()


  useEffect(() => {
    const _unwatch = watchAccount(wagmiConfig, {
      onChange: (
        {address: currentAddress},
        {address: prevAddress, isConnected: isPrevConnected}
      ) => {
        if (isPrevConnected && !!currentAddress && !!prevAddress && prevAddress !== currentAddress) {
          onToast({
            type   : TOAST_TYPE.INFO,
            payload: {
              title: "EVM Account Changed !",
              msg  : "EVM account has been changed",
            },
          })

          if (chains.some(chain => chain.id === sourceChainId)) {
            updateFtTargetTokenAmount("");
            resetNftTargetToken();
          }
        }
      },
    })

    return () => {
      if (_unwatch) _unwatch();
    }
  }, [isConnected, evmAddress, sourceChainId]);

  // useLayoutEffect(() => {
  //   if (!!window.havah && isConnected && !!havahAddress) {
  //     window.havah.on('accountsChanged', debounce((address: string) => {
  //       console.log("accountsChanged", address)
  //       if (isConnected && havahAddress && havahAddress !== address) {
  //         ;(async () => {
  //           await window.havah?.connect()
  //           // await open()
  //         })();
  //       }
  //     }));
  //   }
  // }, [window.havah, isConnected, havahAddress]);

}

export default useWatchAccountChange;