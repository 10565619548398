import {FT_STEP_NAV} from "@resources/@types/fungible-token/ft";
import {
  createFtTransferModalSlice,
  ftTransferModalInitialState
}                    from "@stores/fungible-token/transferModalSlice";
import {
  type Draft,
  produce
}                    from "immer";
import {create}      from "zustand";
import {immer}       from 'zustand/middleware/immer';
import type {
  FtMergedStore,
  FtMergedStoreInitialState,
  FtStoreInitialState
}                    from "./schema";



const initialState: FtStoreInitialState = {
  ft: {
    targetToken           : {
      info  : {
        id      : "",
        name    : "",
        symbol  : "",
        contract: undefined,
      },
      amount: "",
    },
    stepNav               : FT_STEP_NAV.ONE,
    isSubmitBtnActive     : false,
    isUnsignedTxRequesting: false,
  },
};

const useFtStore = create<FtMergedStore>()(
  immer((set, get, store) => ({
    ft: {
      ...initialState.ft,
      ...createFtTransferModalSlice(set, get, store).ft,
    },
    actions      : {
      updateFtTargetTokenInfo       : (targetTokenInfo) => {
        set(produce((state: Draft<FtMergedStoreInitialState>) => {
          state.ft.targetToken.info = targetTokenInfo;
        }));
      },
      updateFtTargetTokenAmount     : (amount) => {
        set(produce((state: Draft<FtMergedStoreInitialState>) => {
          state.ft.targetToken.amount = amount;
        }));
      },
      updateFtStepNav               : (step) => {
        set(produce((state: Draft<FtMergedStoreInitialState>) => {
          state.ft.stepNav = step;
        }));
      },
      updateIsFtSubmitBtnActive     : (isActive) => {
        set(produce((state: Draft<FtMergedStoreInitialState>) => {
          state.ft.isSubmitBtnActive = isActive;
        }));
      },
      updateIsFtUnsignedTxRequesting: (isRequesting) => {
        set(produce((state: Draft<FtMergedStoreInitialState>) => {
          state.ft.isUnsignedTxRequesting = isRequesting;
        }));
      },
      resetFtTargetToken            : () => {
        set(produce((state: Draft<FtMergedStoreInitialState>) => {
          const {targetToken}             = initialState.ft
          state.ft.targetToken = {
            ...targetToken,
            info: {
              ...targetToken.info,
              contract: undefined
            }
          };
        }));
      },
      resetFtTargetTokenAmount      : () => {
        set(produce((state: Draft<FtMergedStoreInitialState>) => {
          state.ft.targetToken.amount = initialState.ft.targetToken.amount;
        }));
      },
      resetFt                       : () => {
        set(produce((state: Draft<FtMergedStoreInitialState>) => {
          state.ft = {...initialState.ft, ...ftTransferModalInitialState.ft};
        }));
      },
      ...createFtTransferModalSlice(set, get, store).actions,
    },
  })));


/**
 * @STATE
 */
export const useFtTargetTokenInfo                 = () => useFtStore((state) => ({ftTargetTokenInfo: state.ft.targetToken.info}))
export const useFtTargetTokenAmount               = () => useFtStore((state) => ({ftTargetTokenAmount: state.ft.targetToken.amount}))
export const useFtIsSubmitBtnActive               = () => useFtStore((state) => ({isFtSubmitBtnActive: state.ft.isSubmitBtnActive}))
export const useFtIsUnsignedTxRequesting          = () => useFtStore((state) => ({isFtUnsignedTxRequesting: state.ft.isUnsignedTxRequesting}))
export const useFtStepNav                         = () => useFtStore((state) => ({ftStepNav: state.ft.stepNav}))
export const useFtIsTransferModalOn               = () => useFtStore((state) => ({isFtTransferModalOn: state.ft.transferModal.isOn}))
export const useFtTransferModalStep               = () => useFtStore((state) => ({ftTransferModalStep: state.ft.transferModal.step}))
export const useFtIsTransferModalTransferring     = () => useFtStore((state) => ({isFtTransferModalTransferring: state.ft.transferModal.isTransferring}))
export const useFtTransferModalProgressTitle      = () => useFtStore((state) => ({ftTransferModalProgressTitle: state.ft.transferModal.progress.title}))
export const useFtTransferModalProgressPercentage = () => useFtStore((state) => ({ftTransferModalProgressPercentage: state.ft.transferModal.progress.percentage}))
export const useFtTransferModalResultType         = () => useFtStore((state) => ({ftTransferModalResultType: state.ft.transferModal.result.type}))
export const useFtTransferModalResultTxHashes     = () => useFtStore((state) => ({ftTransferModalResultTxHashes: state.ft.transferModal.result.txHashes}))

/**
 * @ACTIONS
 */
export const useFtActions = () => useFtStore((state) => state.actions)



