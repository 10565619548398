import type {AssertionConstType} from "@resources/@types/common/type";
import {
  havah,
  zkSyncEra
}                                from "@resources/config/contracts";
import {z}                       from "zod";



export const COMPARE_RETURN = {
  PLUS : 1,
  MINUS: -1,
  EQUAL: 0,
} as const

export const CompareReturn = z.nativeEnum(COMPARE_RETURN)
export type CompareReturn = z.infer<typeof CompareReturn>

export const COMPARE_SYMBOL = {
  [COMPARE_RETURN.PLUS] : "+",
  [COMPARE_RETURN.MINUS]: "-",
  [COMPARE_RETURN.EQUAL]: "",
} as const
export const CompareSymbol  = z.nativeEnum(COMPARE_SYMBOL)
export type CompareSymbol = z.infer<typeof CompareSymbol>

export const BRIDGE_TOKEN_TYPE = {
  FUNGIBLE_TOKEN: "ft",
  NFT           : "nft",
} as const

export const BridgeTokenType = z.nativeEnum(BRIDGE_TOKEN_TYPE)
export type BridgeTokenType = z.infer<typeof BridgeTokenType>


export const UI_DECIMAL     = 9 as const
export const ORIGIN_DECIMAL = 18 as const

export const THEME = {
  DARK : 'dark',
  LIGHT: 'light',
} as const

export const Theme = z.nativeEnum(THEME)
export type Theme = z.infer<typeof Theme>

export const PLATFORM = {
  DESKTOP: 'desktop',
  TABLET : 'tablet',
  MOBILE : 'mobile',
} as const

export const POPUP_STATUS = {
  ON : 'on',
  OFF: 'off'
} as const;

export type PopupStatus = AssertionConstType<typeof POPUP_STATUS>

export const SUCCESS_OR_FAILURE = {
  SUCCESS: 'Success',
  FAILURE: 'Failure',
} as const

export type SuccessOrFailure = AssertionConstType<typeof SUCCESS_OR_FAILURE>

export const HEADER_TOGGLE_MAIN_PAGE = {
  BRIDGE : "Bridge",
  SWAP   : "Swap",
  VESTING: "Vesting",
} as const

export type HeaderToggleMainPage = AssertionConstType<typeof HEADER_TOGGLE_MAIN_PAGE>

export const HEADER_TOGGLE_MAIN_PAGE_PATH = {
  BRIDGE : "/bridge",
  SWAP   : "/swap",
  VESTING: "/vesting",
} as const

export type HeaderToggleMainPagePath = AssertionConstType<typeof HEADER_TOGGLE_MAIN_PAGE_PATH>

export const HEADER_TOGGLE_LIST = import.meta.env.VITE_ENV !== "prod"
    // && import.meta.env.VITE_ENV !== "stage"
    ? [
      {
        value: HEADER_TOGGLE_MAIN_PAGE.BRIDGE,
        path : HEADER_TOGGLE_MAIN_PAGE_PATH.BRIDGE,
      },
      {
        value: HEADER_TOGGLE_MAIN_PAGE.SWAP,
        path : HEADER_TOGGLE_MAIN_PAGE_PATH.SWAP,
      },
      {
        value: HEADER_TOGGLE_MAIN_PAGE.VESTING,
        path : HEADER_TOGGLE_MAIN_PAGE_PATH.VESTING,
      }
    ]
    : [
      {
        value: HEADER_TOGGLE_MAIN_PAGE.BRIDGE,
        path : HEADER_TOGGLE_MAIN_PAGE_PATH.BRIDGE,
      },
    ] as const


export const BRIDGE_HEADER_MENU = import.meta.env.VITE_ENV !== "prod"
  // && import.meta.env.VITE_ENV !== "stage"
  ? {
    NFT     : 'NFTs',
    TOKEN   : 'Tokens',
    EXPLORER: 'Explorer',
  } as const
  : {
    TOKEN: 'Tokens',
  } as const

export const BRIDGE_HEADER_MENU_PATH = import.meta.env.VITE_ENV !== "prod"
  // && import.meta.env.VITE_ENV !== "stage"
  ? {
    NFT     : '/bridge/nft',
    TOKEN   : '/bridge/ft',
    EXPLORER: '/bridge/explorer',
  } as const
  : {
    TOKEN: '/bridge/ft',
  } as const


export const BRIDGE_HEADER_MENU_LIST = import.meta.env.VITE_ENV !== "prod"
  // && import.meta.env.VITE_ENV !== "stage"
  ? [
    {
      value: BRIDGE_HEADER_MENU.NFT,
      path : BRIDGE_HEADER_MENU_PATH.NFT,
    },
    {
      value: BRIDGE_HEADER_MENU.TOKEN,
      path : BRIDGE_HEADER_MENU_PATH.TOKEN,
    },
    {
      value: BRIDGE_HEADER_MENU.EXPLORER,
      path : BRIDGE_HEADER_MENU_PATH.EXPLORER,
    },
  ] as const
  : [
    {
      value: BRIDGE_HEADER_MENU.TOKEN,
      path : BRIDGE_HEADER_MENU_PATH.TOKEN,
    },
  ] as const

export const SWAP_HEADER_MENU = {
  SWAP       : 'Swap',
  LIQUID_POOL: 'Pools',
  FARM       : 'Farm',
} as const

export const SWAP_HEADER_MENU_PATH = {
  SWAP       : '/swap',
  LIQUID_POOL: '/swap/pools',
  FARM       : '/swap/farm',
} as const

export const SWAP_HEADER_MENU_LIST = [
  {
    value: SWAP_HEADER_MENU.SWAP,
    path : SWAP_HEADER_MENU_PATH.SWAP,
  },
  {
    value: SWAP_HEADER_MENU.LIQUID_POOL,
    path : SWAP_HEADER_MENU_PATH.LIQUID_POOL,
  },
  {
    value: SWAP_HEADER_MENU.FARM,
    path : SWAP_HEADER_MENU_PATH.FARM,
  },
] as const


export const FOOTER_SOCIAL_LINK = {
  DISCORD    : 'discord',
  MEDIUM     : 'medium',
  TWITTER    : 'twitter',
  FACEBOOK   : 'facebook',
  TELEGRAM   : 'telegram',
  YOUTUBE    : 'youtube',
  WHITE_PAPER: 'whitePaper',
} as const

export const FOOTER_SOCIAL_PATH = {
  DISCORD    : "https://discord.com/invite/PERPLAY",
  MEDIUM     : "https://medium.com/@PERPLAY",
  TWITTER    : "https://x.com/PERPLAYofficial",
  FACEBOOK   : "https://www.facebook.com/PERPLAYofficial",
  TELEGRAM   : "https://t.me/PERPLAY",
  YOUTUBE    : "https://www.youtube.com/@perplay",
  WHITE_PAPER: "https://docs.perplay.io/perplay-whitepaper",
} as const

export const FOOTER_SOCIAL_LINK_LIST = [
  {
    value: FOOTER_SOCIAL_LINK.DISCORD,
    link : FOOTER_SOCIAL_PATH.DISCORD,
  },
  {
    value: FOOTER_SOCIAL_LINK.MEDIUM,
    link : FOOTER_SOCIAL_PATH.MEDIUM,
  },
  {
    value: FOOTER_SOCIAL_LINK.TWITTER,
    link : FOOTER_SOCIAL_PATH.TWITTER,
  },
  {
    value: FOOTER_SOCIAL_LINK.FACEBOOK,
    link : FOOTER_SOCIAL_PATH.FACEBOOK,
  },
  {
    value: FOOTER_SOCIAL_LINK.TELEGRAM,
    link : FOOTER_SOCIAL_PATH.TELEGRAM,
  },
  {
    value: FOOTER_SOCIAL_LINK.YOUTUBE,
    link : FOOTER_SOCIAL_PATH.YOUTUBE,
  },
  {
    value: FOOTER_SOCIAL_LINK.WHITE_PAPER,
    link : FOOTER_SOCIAL_PATH.WHITE_PAPER,
  },
] as const


export const DESTINATION_WALLET_TYPE = {
  MY   : "myWallet",
  OTHER: "otherWallet",
} as const

export type DestinationWalletType = AssertionConstType<typeof DESTINATION_WALLET_TYPE>

export const DESTINATION_WALLET_TYPE_LIST = [
  {
    label: "My Wallet",
    value: DESTINATION_WALLET_TYPE.MY,
  },
  {
    label: "Other Wallet",
    value: DESTINATION_WALLET_TYPE.OTHER,
  },
] as const

export const PARAMS_CHAIN_NAME = {
  [zkSyncEra.id]: "ZK_SYNC",
  [havah.id]    : "HAVAH",
} as const




