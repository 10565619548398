import {useHavahAccount}                    from "@hooks";
import {TX_RECEIPT_STATUS}                  from "@resources/@types/common/chains/_constant";
import {HAVAH_TX_RECEIPT_STATUS}            from "@resources/@types/common/chains/havah/havah";
import type {HavahNftList}                  from "@resources/@types/common/chains/havah/havahDataApi";
import type {
  HavahBalanceQueryResponse,
  HavahGetApprovedNftQueryParams,
  HavahTokenAllowanceQueryParams,
  HavahTokenBalanceQueryResponse,
  HavahTxReceiptQueryResponse
}                                           from "@resources/@types/common/chains/havah/havahIconApi";
import {
  COMPARE_RETURN,
  ORIGIN_DECIMAL,
  UI_DECIMAL,
}                                           from "@resources/@types/common/constant";
import type {BalanceInfo}                   from "@resources/@types/common/query";
import type {
  HashTypes,
  HavahAddress,
  Receipt
}                                           from "@resources/@types/common/type";
import type {NftGetMetadataQueriesResponse} from "@resources/@types/nft/nft";
import {
  havah,
  havahBridges
}                                           from "@resources/config/contracts";
import {
  havahMutationOptions,
  havahQueryOptions
}                                           from "@services/havah/quries";
import {useCommonSourceChain}               from "@stores/common/store";
import {
  type QueryClient,
  useMutation,
  useQuery,
  useQueryClient
}                                           from '@tanstack/react-query';
import {
  decimalToBigInt,
  formatToDecimalString,
  iconToHexNumber,
}                                           from "@utils/Format";
import {
  compareDecimalString,
  multiply,
  predicateByQueryKey
} from "@utils/Utils";
import {
  isIconAddress,
  isIconEoaAddress,
  isIconScoreAddress
}                                           from "@utils/Validator";
import isNumber                             from "lodash-es/isNumber";
import {
  useCallback,
  useMemo
}                                           from "react";
import {
  formatGwei,
  formatUnits,
  isHash
}                                           from "viem";



export const useHavahConnectQuery = () => useQuery(havahQueryOptions.connect({
  enabled: false,
  // staleTime : 0,
  // gcTime : 0,
  meta             : {
    persist: false
  },
  structuralSharing: (oldData, newData) => oldData === newData ? oldData : newData,
}))

export const useHavahConnectMutation = () => {
  const qc = useQueryClient()
  return useMutation(havahMutationOptions.connect({
    onSuccess: (data) => {

      const {ok, body}            = data
      const {nid, address, error} = body
      const _isSync               = Number(nid) === havah.id

      const _isConnected = (_isSync && !!address && !error)

      qc.setQueryData(
        havahQueryOptions.connect().queryKey,
        ok ?
          {
            isConnected  : _isConnected,
            isNetworkSync: _isSync,
          }
          : {
            isConnected  : false,
            isNetworkSync: false,
          }
      );

      if (_isConnected) {
        qc.setQueryData(
          havahQueryOptions.accounts().queryKey, {
            address,
            nid,
            error
          });
      }
    },
  }))
}

export const useHavahAccountsQuery = () => useQuery(havahQueryOptions.accounts({
  enabled: false,
  // staleTime : 0,
  // gcTime : 0,
  meta: {
    persist: false
  },
}))

export const useHavahSignMessageMutation = () => {
  const {address} = useHavahAccount();

  return useMutation(havahMutationOptions.signMessage(address));
}

export const useHavahSendTxMutation = <T>() => {
  return useMutation(havahMutationOptions.sendTx<T>());
}

export const useHavahTokenBalanceQuery = ({tokenContract, enabled}: {
  tokenContract?: HavahAddress,
  enabled?: boolean
}) => {
  const qc                                 = useQueryClient()
  const {isConnected, address}             = useHavahAccount()
  const {sourceChain: {id: sourceChainId}} = useCommonSourceChain()

  const initialBalanceData = {
    name              : "",
    symbol            : "",
    uiBalanceOf       : "0",
    formattedBalanceOf: "0",
    originBalanceOf   : 0n,
    decimals          : 0,
  }


  const tokenBalanceOption = havahQueryOptions.tokenBalance(
    {
      address      : address,
      tokenContract: tokenContract,
    },
    {
      enabled  : (
        (enabled ?? true)
        && (!!sourceChainId && sourceChainId === havah.id)
        && (!!tokenContract && isIconAddress(tokenContract))
        && (isConnected && !!address && isIconEoaAddress(address))
      ),
      staleTime: 0,
      gcTime   : 0,
    }
  )

  const {data: havahTokenBalanceData, ...rest} = useQuery(tokenBalanceOption)

  const convertBalanceData = useCallback((data?: HavahTokenBalanceQueryResponse): BalanceInfo => {
    if (!data) return initialBalanceData;

    const {
            name,
            symbol,
            balanceOf,
            decimals
          } = data

    const numberingDecimals = Number(decimals)

    const _bigintBalanceOf = decimalToBigInt(balanceOf)


    return {
      name              : name,
      symbol            : symbol,
      uiBalanceOf       : formatToDecimalString(_bigintBalanceOf, UI_DECIMAL),
      formattedBalanceOf: formatUnits(_bigintBalanceOf, ORIGIN_DECIMAL),
      originBalanceOf   : _bigintBalanceOf,
      decimals          : numberingDecimals,
    }
  }, [havahTokenBalanceData])


  return {
    data    : useMemo(() => convertBalanceData(havahTokenBalanceData), [convertBalanceData]),
    queryKey: tokenBalanceOption.queryKey,
    ...rest,
  }
}


export const useHavahNativeBalanceQuery = (enabled?: boolean) => {
  const {isConnected, address} = useHavahAccount()

  const initialNativeBalanceData = {
    name              : "HVH Token",
    symbol            : havah.nativeCurrency.symbol,
    uiBalanceOf       : "0",
    formattedBalanceOf: "0",
    originBalanceOf   : 0n,
    decimals          : havah.nativeCurrency.decimals,
  }


  const {data: havahBalanceData, ...rest} = useQuery(havahQueryOptions.balance(
    {address},
    {
      enabled  : (
        (enabled ?? false)
        && (isConnected && !!address && isIconEoaAddress(address))
      ),
      staleTime: 0,
      gcTime   : 0,
    }
  ));

  const convertBalanceData = useCallback((data?: HavahBalanceQueryResponse): BalanceInfo => {
    if (!data) return initialNativeBalanceData

    const {balanceOf} = data

    const _bigintBalanceOf = decimalToBigInt(iconToHexNumber(balanceOf))
    const _decimals        = initialNativeBalanceData.decimals

    return {
      name              : "HVH Token",
      symbol            : havah.nativeCurrency.symbol,
      uiBalanceOf       : formatToDecimalString(_bigintBalanceOf, UI_DECIMAL),
      formattedBalanceOf: formatUnits(_bigintBalanceOf, _decimals),
      originBalanceOf   : _bigintBalanceOf,
      decimals          : _decimals,
    }
  }, [havahBalanceData])


  return {
    data: useMemo(() => convertBalanceData(havahBalanceData), [convertBalanceData]),
    ...rest,
  }
}


export const useHavahTxReceiptQuery = ({chainId, txHash, enabled}: {
  chainId: number;
  txHash?: HashTypes;
  enabled?: boolean;
}) => {
  const {address, isConnected} = useHavahAccount()

  const {data: txReceiptData, ...rest} = useQuery(havahQueryOptions.txReceipt(
    {address: address, txHash: txHash},
    {
      enabled: (
        (enabled ?? true)
        && (isConnected && !!address && isIconEoaAddress(address))
        && (!!txHash && isHash(txHash))
        && (!!chainId && chainId === havah.id)
      ),
    }
  ))


  const initialReceipt = {
    txHash  : null,
    txLink  : "",
    txStatus: TX_RECEIPT_STATUS.NOT_FOUND,
    txFee   : "0",
  }


  const convertReceipt = useCallback((data?: HavahTxReceiptQueryResponse): Receipt => {
    if (!data) return initialReceipt

    if (!!data.failure) {
      console.error("Receipt data Has failure : ", data.failure)
    }

    const {
            txHash,
            stepUsed,
            status,
            stepPrice,
            // blockHeight,
            // eventLogs,
            // failure,
            // cumulativeStepUsed,
            // to,
            // blockHeight,
            // eventLogs,
            // logsBloom,
            // scoreAddress,
            // txIndex,
            // blockHash,
          } = data

    const _numberingStepUsed  = Number(stepUsed)
    const _numberingStepPrice = Number(stepPrice)

    const _bigintTxFee = BigInt(multiply(_numberingStepUsed, _numberingStepPrice))
    const _gweiTxFee   = formatGwei(_bigintTxFee)

    return {
      txHash  : txHash,
      txLink  : `${havah.blockExplorers.default.url}/txn/${txHash}`,
      txStatus: {
        [HAVAH_TX_RECEIPT_STATUS.SUCCESS]: TX_RECEIPT_STATUS.SUCCESS,
        [HAVAH_TX_RECEIPT_STATUS.FAILURE]: TX_RECEIPT_STATUS.FAILURE,
      }[status],
      txFee   : `${_gweiTxFee} Gwei`,
    }
  }, [txReceiptData])


  return {
    data: useMemo(() => convertReceipt(txReceiptData), [convertReceipt]),
    ...rest,
  }
}


export const useHavahNftListQuery = (enabled?: boolean) => {
  const qc                                 = useQueryClient()
  const {address, isConnected}             = useHavahAccount()
  const {sourceChain: {id: sourceChainId}} = useCommonSourceChain()

  const nftListQueryOption = havahQueryOptions.nftList(
    {address: address},
    {
      enabled  : (
        (enabled ?? true)
        && (isConnected && !!address && isIconEoaAddress(address))
        && sourceChainId === havah.id
      ),
      staleTime: 1000 * 60,
      gcTime   : 1000 * 60 * 3,
    }
  )

  const {
          data: nftListData,
          isFetching,
        } = useQuery(nftListQueryOption);


  const initialNftListData: { total: number, list: NftGetMetadataQueriesResponse[] } = {
    total: 0,
    list : []
  }


  const convertNftListData = useCallback((data?: HavahNftList) => {
    if (!data) return initialNftListData;

    const {total, nfts} = data

    if (!total) return initialNftListData

    const _convertedList: NftGetMetadataQueriesResponse[] = nfts.map(({name, tokenUri, imageUri, tokenId}) => ({
      data     : {
        name    : name,
        id      : tokenId,
        imageUri: imageUri,
        uri     : tokenUri,
      },
      isLoading: false,
    } as NftGetMetadataQueriesResponse))

    return {
      total: total,
      list : _convertedList,
    }
  }, [nftListData])


  return {
    data     : useMemo(() => convertNftListData(nftListData), [convertNftListData]),
    isLoading: isFetching,
  }
}


export const fetchHavahTokenAllowanceQuery = (qc: QueryClient) => async ({address, tokenContract, spenderContract, transferTokenAmount, enabled}:
  Omit<HavahTokenAllowanceQueryParams, "address"> & {
  address: HavahAddress;
  transferTokenAmount?: string;
  enabled?: boolean;
}) => {

  const tokenAllowanceData = await qc.fetchQuery(havahQueryOptions.tokenAllowance({
    address        : address,
    tokenContract  : tokenContract,
    spenderContract: spenderContract,
  }, {
    enabled  : (
      (!!enabled)
      && (!!address && isIconEoaAddress(address))
      && (!!tokenContract && isIconScoreAddress(tokenContract))
      && (!!spenderContract && isIconScoreAddress(spenderContract))
    ),
    gcTime   : 0,
    staleTime: 0,
  }))


  const initialAllowance = {
    canSkipApprove: false,
    formatted     : "0",
    value         : 0n
  }

  const convertData = async ({data, transferTokenAmount}: {
    data?: bigint;
    transferTokenAmount?: string;
  }) => {
    if (!data || !transferTokenAmount) return initialAllowance

    const _formatted            = formatUnits(data, ORIGIN_DECIMAL)
    const _hasAllowance         = compareDecimalString(data, 0n) === COMPARE_RETURN.PLUS
    const _compareRequestAmount = compareDecimalString(transferTokenAmount, _formatted) <= COMPARE_RETURN.EQUAL


    return {
      canSkipApprove: _hasAllowance && _compareRequestAmount,
      formatted     : _formatted,
      value         : data,
    }
  }


  return await convertData({
    data               : tokenAllowanceData,
    transferTokenAmount: transferTokenAmount,
  })
}


export const fetchHavahGetApprovedNftQuery = (qc: QueryClient) => async ({address, tokenId, enabled}:
  HavahGetApprovedNftQueryParams & {
  enabled?: boolean;
}) => {
  qc.removeQueries({
    predicate: predicateByQueryKey({scopeKey: "getApprovedNft"}),
  })

  const getApprovedNftData = await qc.fetchQuery(havahQueryOptions.getApprovedNft({
    address: address,
    tokenId: tokenId,
  }, {
    enabled: (
      (enabled ?? true)
      && (!!address && isIconEoaAddress(address))
      && (!!tokenId && isNumber(tokenId))
    )
  }))


  const initialGetApprovedNft = {
    canSkipApprove: false,
    value         : ""
  }

  const convertData = async (data?: HavahAddress) => {
    if (!data) return initialGetApprovedNft

    return {
      canSkipApprove: data === havahBridges.PERPLAY_NFT_BRIDGE.contract,
      value         : data,
    }
  }

  return await convertData(getApprovedNftData)
}


