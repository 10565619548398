import type {InfoFee}                        from "@components/UI/molecules/TransferFee";
import type {ArbitrumOneUnsignedTx}          from "@resources/@types/common/chains/evm/arbitrum";
import type {ZkSyncEraUnsignedTx}               from "@resources/@types/common/chains/evm/zkSync";
import {
  HAVAH_UNSIGNED_TX_METHOD_TYPE,
  type HavahUnsignedTx,
  type HavahUnsignedTxData
}                                            from "@resources/@types/common/chains/havah/havah";
import {
  type CompareSymbol,
  type DestinationWalletType,
  type SuccessOrFailure,
}                                            from "@resources/@types/common/constant";
import {
  type BalanceInfo,
  GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME,
  type GetEvmUnsignedTxMutationVariablesChainName,
  GetUnsignedTxMutationVariablesChainName,
  SERVICE_TOKEN_NAME,
  type UnsignedTxFees
} from "@resources/@types/common/query";
import {
  AddressTypes,
  type AssertionConstType,
  EvmAddress,
  type HashTypes,
  HavahAddress,
  type Receipt
}                                            from "@resources/@types/common/type";
import Nft                                   from "@router/loaders/nft";
import type {
  ChainInfo,
  NftInfo,
  TokenInfo
}                                            from "@stores/common/schema";
import {FtTransferModalResultTxHashesSchema} from "@stores/fungible-token/schema";
import type {UseMutateAsyncFunction}         from "@tanstack/react-query";
import {
  isEvmAddress,
  isIconEoaAddress
}                                            from "@utils/Validator";
import type {ReactNode}                      from "react";
import {z}                                   from "zod";



export const NFT_SERVICE_TOKEN_NAME = {
  XPER_BRIDGE: SERVICE_TOKEN_NAME.XPER_BRIDGE,
  PER_BRIDGE : SERVICE_TOKEN_NAME.PER_BRIDGE,
  ARB_BRIDGE : SERVICE_TOKEN_NAME.ARB_BRIDGE,
} as const

export const NftServiceTokenName = z.nativeEnum(NFT_SERVICE_TOKEN_NAME)
export type NftServiceTokenName = z.infer<typeof NftServiceTokenName>

export const NFT_STEP_NAV = {
  ONE        : 1,
  TWO        : 2,
  THREE      : 3,
  NEXT       : 4,
  ON_TRANSFER: 5,
} as const
export type NftStepNav = AssertionConstType<typeof NFT_STEP_NAV>

export const GET_NFT_UNSIGNED_TX_MUTATION_VARIABLES_TOKEN_NAME = {
  PERPLAY: "PERPLAY_NFT",
} as const

export const GetNftUnsignedTxMutationVariablesTokenName = z.nativeEnum(GET_NFT_UNSIGNED_TX_MUTATION_VARIABLES_TOKEN_NAME)
export type GetNftUnsignedTxMutationVariablesTokenName = z.infer<typeof GetNftUnsignedTxMutationVariablesTokenName>

type ChainNames = GetUnsignedTxMutationVariablesChainName
type EvmChainNames = GetEvmUnsignedTxMutationVariablesChainName
/** @GET-UNSIGNED-TX-CONTAINER **/
type Account = {
  isConnected: boolean;
  hasAddress: boolean;
}

type NftMakeRequestVariablesProps = Omit<CommonGetNftUnsignedTxContainerPropsType, "children"> & {
  evm: Account & { address?: EvmAddress };
  havah: Account & { address?: HavahAddress };
}

export type NftMakeRequestVariablesReturn =
  NftGetUnsignedBridgeTxByChainVariables
  & {
    canSkipApprove: boolean
  }

export type NftMakeRequestVariables = (props: NftMakeRequestVariablesProps) => Promise<false | NftMakeRequestVariablesReturn>


export type GetNftGetUnsignedTxContainerByChainChildrenPropsType = {
  sourceChain: ChainInfo;
  destinationChain: ChainInfo;
  tokenId: number;
  makeRequestVariables: () => Promise<false | NftMakeRequestVariablesReturn>
  getUnsignedBridgeTxByChainMutateAsync:
    UseMutateAsyncFunction<NftGetUnsignedBridgeLockTxMutationResponse, any, NftGetUnsignedBridgeTxByChainVariables, unknown>
    | UseMutateAsyncFunction<NftGetUnsignedBridgeBurnTxMutationResponse<EvmChainNames>, any, NftGetUnsignedBridgeTxByChainVariables, unknown>
}

export type GetNftGetUnsignedTxContainerByChainChildrenType = (props: GetNftGetUnsignedTxContainerByChainChildrenPropsType) => ReactNode

export type CommonGetNftUnsignedTxContainerPropsType = {
  sourceChain: ChainInfo;
  destinationChain: ChainInfo;
  tokenId: number;
  walletType: DestinationWalletType
  otherWalletAddress: string;
  children: GetNftGetUnsignedTxContainerByChainChildrenType
}

export type GetNftGetUnsignedTxContainerByChainPropsType = CommonGetNftUnsignedTxContainerPropsType


/** @SIGNING-TX-CONTAINER **/
export type NftInfoFees = {
  myBalance: InfoFee;
  commissionFee: InfoFee;
  destinationFee: InfoFee;
  remainBalance: [compareSymbol: CompareSymbol, ...InfoFee];
  isBalanceSufficient: boolean
}


export type NftReceiptInfo = {
  isLoading: boolean;
  receipt: Receipt
}

export type NftReceipts = {
  sourceApproveTxReceipt: NftReceiptInfo & {
    useReceipt: boolean;
  };
  sourceTxByChainReceipt: NftReceiptInfo & {
    title: string;
  };
  destinationTxByChainReceipt: NftReceiptInfo & {
    title: string;
    canRefetch: boolean;
    refetch: () => void;
  };
}

export type GetNftSigningContainerByChainChildrenPropsType = (props: {
  onClickTransfer: () => void;
  infoFees: NftInfoFees
  fromSplitAddress?: AddressTypes;
  toSplitAddress?: AddressTypes;
  receipts: NftReceipts
}) => ReactNode


export type NftSigningContainerPropsType<chainName extends ChainNames = ChainNames, T extends boolean = boolean> = {
  sourceChainId: number;
  destinationChainId: number;
  tokenId: number;
  fromAddress: chainName extends typeof GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH ? HavahAddress : EvmAddress;
  toAddress: chainName extends typeof GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH ? EvmAddress : AddressTypes;
  unsignedNftApproveTx: T extends true
    ? chainName extends typeof GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH ? HavahNftApproveUnsignedTx : EvmNftUnsignedTx<Extract<chainName, EvmChainNames>>
    : undefined;
  unsignedNftBridgeTxByChain: T extends true
    ? undefined
    : chainName extends typeof GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH ? HavahNftBridgeLockUnsignedTx : EvmNftUnsignedTx<Extract<chainName, EvmChainNames>>
  fees: UnsignedTxFees;
  isFirstRequestApprove: T
  children: GetNftSigningContainerByChainChildrenPropsType;
}

export type GetNftSigningContainerByChainPropsType<chainName extends ChainNames = ChainNames> = NftSigningContainerPropsType<chainName>;


export type NftResultInfoFn = (info: {
  type?: SuccessOrFailure,
  storeTxHashInfo?: {
    storeKey: z.infer<ReturnType<typeof FtTransferModalResultTxHashesSchema.keyof>>;
    txHash: HashTypes
  }
}) => void

export type NftMakeFeesFn = (arg: {
  dataFees?: UnsignedTxFees;
  balanceInfo?: BalanceInfo;
}) => NftInfoFees

export type NftTransferFnPropsType<chainName extends ChainNames> =
  Omit<NftSigningContainerPropsType<chainName>, "fees" | "children" | "nftInfo">
  & {
    tokenId: number;
  }
export type NftTransferFnSecondSequencePropsType<chainName extends ChainNames> =
  Omit<NftTransferFnPropsType<chainName>, "unsignedNftApproveTx" | "isFirstRequestApprove">
  & {
    canCallGetTxApi: boolean;
  }


/** @EVM */
type EvmChainTxTypes<T extends EvmChainNames> = {
  [GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.ZK_SYNC]: ZkSyncEraUnsignedTx;
  [GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.ARBITRUM]: ArbitrumOneUnsignedTx;
}[T]
export type EvmNftUnsignedTx<evmChainName extends EvmChainNames = EvmChainNames> = EvmChainTxTypes<evmChainName>



/** @HAVAH */
export interface HavahNftApproveUnsignedTxDataParams {
  _approved: string;
  _tokenId: string;
}



export interface HavahNftLockUnsignedTxDataParams {
  // from: string;
  to: string;
  signatureArray: string[];
  uuid: string;
  tokenId: string;
  tokenURI: string;
  // notary: string;
  // commissionAmount: string;
  // destinationFeeAmount: string;
  // signature: string;
}



export type HavahNftApproveUnsignedTxData = HavahUnsignedTxData<typeof HAVAH_UNSIGNED_TX_METHOD_TYPE.APPROVE, HavahNftApproveUnsignedTxDataParams>
export type HavahNftApproveUnsignedTx = HavahUnsignedTx<HavahNftApproveUnsignedTxData>

export type HavahNftBridgeLockUnsignedTxData = HavahUnsignedTxData<typeof HAVAH_UNSIGNED_TX_METHOD_TYPE.LOCK, HavahNftLockUnsignedTxDataParams>
export type HavahNftBridgeLockUnsignedTx = HavahUnsignedTx<HavahNftBridgeLockUnsignedTxData>

export type HavahNftSendTxMutationVariablesGeneric = HavahNftApproveUnsignedTxData | HavahNftBridgeLockUnsignedTxData

/** @Response-And-Params */
//== Approve
export type NftGetUnsignedApproveTxMutationResponse<chainName extends ChainNames> = UnsignedTxFees & {
  unsignedNftApproveTransaction: chainName extends typeof GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH
    ? HavahNftApproveUnsignedTx
    : EvmNftUnsignedTx<Extract<chainName, EvmChainNames>>
}

export const NftGetUnsignedApproveTxMutationVariables = z.object({
  chainName: GetUnsignedTxMutationVariablesChainName,
  from     : AddressTypes,
  tokenId  : z.number(),
  tokenName: GetNftUnsignedTxMutationVariablesTokenName,
}).refine(data => {
  if (!isEvmAddress(data.from) && !isIconEoaAddress(data.from)) return false;


  const {data: _parsedChainName, success: _successChainName, error: _errorChainName} = GetUnsignedTxMutationVariablesChainName.safeParse(data.chainName)
  if (!_parsedChainName || !_successChainName || _errorChainName) return false;

  const {data: _parsedTokenName, success: _successTokenName, error: _errorTokenName} = GetNftUnsignedTxMutationVariablesTokenName.safeParse(data.tokenName)
  if (!_parsedTokenName || !_successTokenName || _errorTokenName) return false;

  if (data.chainName === GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH) {
    return (
      isIconEoaAddress(data.from) && !!z.number().safeParse(data.tokenId)?.data
    )
  } else {
    return (
      isEvmAddress(data.from) && !!z.number().safeParse(data.tokenId)?.data
    )
  }
})
export type NftGetUnsignedApproveTxMutationVariables = z.infer<typeof NftGetUnsignedApproveTxMutationVariables>;


//== Tx By Chain
/** @EVM */
export type NftGetUnsignedBridgeBurnTxMutationResponse<chainName extends EvmChainNames> = UnsignedTxFees & {
  unsignedNftBridgeBurnTransaction: EvmNftUnsignedTx<chainName>;
}

/** @HAVAH */
export type NftGetUnsignedBridgeLockTxMutationResponse = UnsignedTxFees & {
  unsignedNftBridgeLockTransaction: HavahNftBridgeLockUnsignedTx;
}


export type NftGetUnsignedBridgeTxByChainResponse<chainName extends ChainNames> =
  chainName extends typeof GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH
    ? NftGetUnsignedBridgeLockTxMutationResponse
    : NftGetUnsignedBridgeBurnTxMutationResponse<Extract<chainName, EvmChainNames>>


export const NftGetUnsignedBridgeTxByChainVariables = z.object({
  chainName: GetUnsignedTxMutationVariablesChainName,
  from     : AddressTypes,
  to       : AddressTypes,
  tokenId  : z.number(),
  tokenName: GetNftUnsignedTxMutationVariablesTokenName,
}).refine(data => {
  if (!isEvmAddress(data.from) && !isIconEoaAddress(data.from)) return false;
  if (!isEvmAddress(data.to) && !isIconEoaAddress(data.to)) return false;


  const {data: _parsedChainName, success: _successChainName, error: _errorChainName} = GetUnsignedTxMutationVariablesChainName.safeParse(data.chainName)
  if (!_parsedChainName || !_successChainName || _errorChainName) return false;

  const {data: _parsedTokenName, success: _successTokenName, error: _errorTokenName} = GetUnsignedTxMutationVariablesChainName.safeParse(data.chainName)
  if (!_parsedTokenName || !_successTokenName || _errorTokenName) return false;

  if (data.chainName === GET_UNSIGNED_TX_MUTATION_VARIABLES_CHAIN_NAME.HAVAH) {
    return (
      isIconEoaAddress(data.from)
      && isEvmAddress(data.to)
      && !!z.number().parse(data.tokenId)
    )
  } else {
    return (
      isEvmAddress(data.from)
      && (isEvmAddress(data.to) || isIconEoaAddress(data.to))
      && !!z.number().parse(data.tokenId)
    )
  }
})
export type NftGetUnsignedBridgeTxByChainVariables = z.infer<typeof NftGetUnsignedBridgeTxByChainVariables>;



export interface NftSendSignedTxResponse {
  transactionHash: EvmAddress
}



export type NftSendSignedTxVariables = {
  chainName: EvmChainNames;
  signedTransaction: EvmAddress;
}



export interface NftGetTokenImageQueryResponse {
  chainName: GetUnsignedTxMutationVariablesChainName,
  from: AddressTypes;
  to: AddressTypes;
  tokenId: number;
}



export interface NftGetTokenImageQueryParams {
  imageUri?: string;
  address?: AddressTypes;
  id?: number;
}



export interface NftMetadataResponse {
  attributes: any[];
  image: string;
  description: string;
  external_url: string;
  name: string;
}



export interface NftGetMetadataQueryResponse {
  id: number;
  name: string;
  uri: string;
  imageUri: string;
}



export type NftGetMetadataQueriesResponse = { data: NftInfo } & {
  isLoading: boolean;
}



export interface NftGetMetadataQueryParams {
  tokenUri?: string;
  address?: AddressTypes;
  id?: number;
}

