import {FT_STEP_NAV}  from "@resources/@types/fungible-token/ft";
import {NFT_STEP_NAV} from "@resources/@types/nft/nft";
import type {FtMergedStoreInitialState} from "@stores/fungible-token/schema";
import {
  type NftMergedStore,
  type NftMergedStoreInitialState,
  type NftStoreInitialState
}                                                  from "@stores/nft/schema";
import {
  createNftTransferModalSlice,
  nftTransferModalInitialState
}                                                  from "@stores/nft/transferModalSlice";
import {
  type Draft,
  produce
}                                                  from "immer";
import {create}                                    from "zustand";
import {immer}                                     from 'zustand/middleware/immer';



const initialState: NftStoreInitialState = {
  nft: {
    targetToken           : {
      info  : {
        id      : 0,
        name    : "",
        imageUri: "",
        uri     : "",
      },
      amount: "",
    },
    stepNav               : NFT_STEP_NAV.ONE,
    isSubmitBtnActive     : false,
    isUnsignedTxRequesting: false,
  },
};

const useNftStore = create<NftMergedStore>()(
  immer((set, get, store) => ({
    nft    : {
      ...initialState.nft,
      ...createNftTransferModalSlice(set, get, store).nft
    },
    actions: {
      updateNftTargetTokenInfo       : (targetTokenInfo) => {
        set(produce((state: Draft<NftMergedStoreInitialState>) => {
          state.nft.targetToken.info = targetTokenInfo;
        }));
      },
      updateNftTargetTokenAmount     : (amount) => {
        set(produce((state: Draft<NftMergedStoreInitialState>) => {
          state.nft.targetToken.amount = amount;
        }));
      },
      updateNftStepNav               : (step) => {
        set(produce((state: Draft<NftMergedStoreInitialState>) => {
          state.nft.stepNav = step;
        }));
      },
      updateIsNftSubmitBtnActive     : (isActive) => {
        set(produce((state: Draft<NftMergedStoreInitialState>) => {
          state.nft.isSubmitBtnActive = isActive;
        }));
      },
      updateIsNftUnsignedTxRequesting: (isRequesting) => {
        set(produce((state: Draft<NftMergedStoreInitialState>) => {
          state.nft.isUnsignedTxRequesting = isRequesting;
        }));
      },
      resetNftTargetToken            : () => {
        set(produce((state: Draft<NftMergedStoreInitialState>) => {
          state.nft.targetToken = initialState.nft.targetToken;
        }));
      },
      resetNft                       : () => {
        set(produce((state: Draft<NftMergedStoreInitialState>) => {
          state.nft = {...initialState.nft, ...nftTransferModalInitialState.nft};
        }));
      },
      ...createNftTransferModalSlice(set, get, store).actions
    },
  })));


/**
 * @STATE
 */
export const useNftTargetTokenInfo                 = () => useNftStore((state) => ({nftTargetTokenInfo: state.nft.targetToken.info}))
export const useNftTargetTokenAmount               = () => useNftStore((state) => ({nftTargetTokenAmount: state.nft.targetToken.amount}))
export const useNftIsSubmitBtnActive               = () => useNftStore((state) => ({isNftSubmitBtnActive: state.nft.isSubmitBtnActive}))
export const useNftIsUnsignedTxRequesting          = () => useNftStore((state) => ({isNftUnsignedTxRequesting: state.nft.isUnsignedTxRequesting}))
export const useNftStepNav                         = () => useNftStore((state) => ({nftStepNav: state.nft.stepNav}))
export const useNftIsTransferModalOn               = () => useNftStore((state) => ({isNftTransferModalOn: state.nft.transferModal.isOn}))
export const useNftIsTransferModalTransferring     = () => useNftStore((state) => ({isNftTransferModalTransferring: state.nft.transferModal.isTransferring}))
export const useNftTransferModalStep               = () => useNftStore((state) => ({nftTransferModalStep: state.nft.transferModal.step}))
export const useNftTransferModalProgressTitle      = () => useNftStore((state) => ({nftTransferModalProgressTitle: state.nft.transferModal.progress.title}))
export const useNftTransferModalProgressPercentage = () => useNftStore((state) => ({nftTransferModalProgressPercentage: state.nft.transferModal.progress.percentage}))
export const useNftTransferModalResultType         = () => useNftStore((state) => ({nftTransferModalResultType: state.nft.transferModal.result.type}))
export const useNftTransferModalResultTxHashes     = () => useNftStore((state) => ({nftTransferModalResultTxHashes: state.nft.transferModal.result.txHashes}))
/**
 * @ACTIONS
 */
export const useNftActions                         = () => useNftStore((state) => state.actions)



