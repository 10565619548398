import {SUCCESS_OR_FAILURE}                        from "@resources/@types/common/constant";
import {
  MODAL_TRANSFER_DIRECTION,
  MODAL_TRANSFER_STEP
}                                                  from "@resources/@types/modal/modal";
import type {FtMergedStoreInitialState} from "@stores/fungible-token/schema";
import type {ImmerStateCreator}                    from "@stores/index";
import {
  type Draft,
  produce
}                                                  from "immer";
import {
  NftMergedStore,
  NftMergedStoreInitialState,
  NftTransferModalSlice,
  NftTransferModalSliceInitialState,
}                                                  from "./schema";

export const nftTransferModalInitialState: NftTransferModalSliceInitialState = {
  nft: {
    transferModal: {
      isOn          : false,
      step          : {
        name     : MODAL_TRANSFER_STEP.INFO_CHECK,
        direction: MODAL_TRANSFER_DIRECTION.NONE,
      },
      progress      : {
        title     : "",
        percentage: 0,
      },
      isTransferring: false,
      result        : {
        type    : SUCCESS_OR_FAILURE.FAILURE,
        txHashes: {
          sourceApproveTxHash: undefined,
          sourceTxHashByChain: undefined,
        },
      },
    },
  }
};

export const createNftTransferModalSlice: ImmerStateCreator<NftMergedStore, NftTransferModalSlice> = (set, get, store) => ({
  ...nftTransferModalInitialState,
  actions: {
    updateIsNftTransferModalOn              : (isOn) => {
      set(produce((state: Draft<NftMergedStoreInitialState>) => {
        state.nft.transferModal.isOn = isOn;
      }));
    },
    updateNftTransferModalStep              : (step) => {
      set(produce((state: Draft<NftMergedStoreInitialState>) => {
        state.nft.transferModal.step = step;
      }));
    },
    updateIsNftTransferModalTransferring    : (isTransferring) => {
      set(produce((state: Draft<NftMergedStoreInitialState>) => {
        state.nft.transferModal.isTransferring = isTransferring;
      }));
    },
    updateNftTransferModalProgressTitle     : (title) => {
      set(produce((state: Draft<NftMergedStoreInitialState>) => {
        state.nft.transferModal.progress.title = title;
      }));
    },
    updateNftTransferModalProgressPercentage: (percentage) => {
      set(produce((state: Draft<NftMergedStoreInitialState>) => {
        state.nft.transferModal.progress.percentage = percentage;
      }));
    },
    updateNftTransferModalResultType        : (resultType) => {
      set(produce((state: Draft<NftMergedStoreInitialState>) => {
        state.nft.transferModal.result.type = resultType;
      }));
    },
    updateNftTransferModalResultTxHash      : (key, txHash) => {
      set(produce((state: Draft<NftMergedStoreInitialState>) => {
        state.nft.transferModal.result.txHashes[key] = txHash;
      }));
    },
    resetNftTransferModal                   : () => {
      set(produce((state: Draft<NftMergedStoreInitialState>) => {
        state.nft.transferModal = nftTransferModalInitialState.nft.transferModal;
      }));
    }
  }
});

