import loadable from "@loadable/component";

import Root from './Root';


/** @COMMON */
const ServerMaintenancePage = loadable(() => import('./ServerMaintenancePage'), {})
/** @BRIDGE */
const NFTPage               = loadable(() => import('./bridge/NftPage'), {})
const FtPage                = loadable(() => import('./bridge/FtPage'), {})
const ExplorerPage          = loadable(() => import('./bridge/ExplorerPage'), {})
const ExplorerDetailsPage   = loadable(() => import('./bridge/ExplorerDetailsPage'), {})
/** @SWAP */
const SwapPage              = loadable(() => import('./swap/SwapPage'), {})
const FarmPage              = loadable(() => import('./swap/FarmPage'), {})
const LiquidPoolPage        = loadable(() => import('./swap/LiquidPoolPage'), {})
/** @VESTING */
const VestingPage           = loadable(() => import('./vesting/VestingPage'), {})

export {
  Root,
  /** @COMMON */
    ServerMaintenancePage,
  /** @BRIDGE */
    NFTPage,
  FtPage,
  ExplorerPage,
  ExplorerDetailsPage,
  /** @SWAP */
    SwapPage,
  /** @VESTING */
    VestingPage,
  FarmPage,
  LiquidPoolPage,
}
