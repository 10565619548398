import {web3Modal}      from "@components/container/WagmiReactQueryContainer";
// import {appKit}         from "@components/container/WagmiReactQueryContainer";


import {useCommonTheme} from "@stores/common/store";
import {useEffect}      from "react";
import useRootEl        from "./useRootEl";



const useDocTheme = () => {
  const {$docRef}      = useRootEl();
  // const {setThemeMode} = appKit
  const {theme}        = useCommonTheme()

  useEffect(() => {
    web3Modal.setThemeMode(theme)
    // setThemeMode(theme)
  }, [theme]);


  // useEffect(() => {
  //   // $docRef.current.setAttribute("data-theme", theme)
  // }, [theme]);
  //
  // useEffect(() => {
  //   $docRef.current.setAttribute("data-theme", "light")
  // }, []);
}

export default useDocTheme;
