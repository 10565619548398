import {
  ExplorerDetailsPage,
  ExplorerPage,
  FarmPage,
  FtPage,
  LiquidPoolPage,
  NFTPage,
  Root,
  ServerMaintenancePage,
  SwapPage,
    VestingPage
} from "@components/pages";
import type {QueryClient} from "@tanstack/react-query";
import {parse} from "@utils/JSON";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route
} from "react-router-dom";


interface PropsType {
  qc: QueryClient
}


const Router = ({qc}: PropsType) =>
  createBrowserRouter(
    createRoutesFromElements([
      <Route
        path="/"
        element={<Root/>}
        // loader={rootLoader(qc)}
      >
        {parse(import.meta.env.VITE_IS_MAINTENANCE)
          ? <Route index element={<ServerMaintenancePage/>}/>
          : <>
            <Route index element={<Navigate to="bridge" replace/>}/>
            {/** @BRIDGE */}
            <Route path="bridge">
              <Route index element={<Navigate to="ft" replace/>}/>
              {/** @NFT */}
              {parse(import.meta.env.VITE_IS_INNER_NETWORK)
                && (
                  <Route
                    path="nft"
                    element={<NFTPage/>}
                    caseSensitive
                    // loader={nftLoader(qc)}
                  />
                )}
              {/** @TOKEN */}
              <Route
                path="ft"
                element={<FtPage/>}
                caseSensitive
                // loader={fungibleTokenLoader(qc)}
              />
              {/** @EXPLORER **/}
              {parse(import.meta.env.VITE_IS_INNER_NETWORK)
                && (
                  <Route path="explorer">
                    <Route
                      index
                      element={<ExplorerPage/>}
                      // loader={explorerLoader(qc)}
                    />
                    <Route
                      path=":id"
                      element={<ExplorerDetailsPage/>}
                      // loader={explorerDetailsLoader(qc)}
                    />
                  </Route>
                )}
            </Route>
            {/** @SWAP */}
            {parse(import.meta.env.VITE_IS_INNER_NETWORK)
              && (
                <Route path="swap">
                  <Route
                    index
                    element={<SwapPage/>}
                    caseSensitive
                    // loader={fungibleTokenLoader(qc)}
                  />
                  <Route
                    path="pools"
                    element={<LiquidPoolPage/>}
                    caseSensitive
                    // loader={fungibleTokenLoader(qc)}
                  />
                  <Route
                    path="farm"
                    element={<FarmPage/>}
                    caseSensitive
                    // loader={fungibleTokenLoader(qc)}
                  />
                </Route>
              )}
            {/** @VESTING */}
            {parse(import.meta.env.VITE_IS_INNER_NETWORK)
                && (
                    <Route
                    path="vesting"
                    element={<VestingPage/>}
                    caseSensitive
                    // loader={vestingLoader(qc)}
                    />
                )}
          </>
        }
      </Route>,
      <Route path="/*" element={<Navigate to="/" replace/>}/>,
    ]),
  )


export default Router;
