import type {CaipNetwork}           from "@reown/appkit";
import type {TxReceiptStatus}       from "@resources/@types/common/chains/_constant";
import type {
  UseMutationOptions,
  UseQueryOptions
}                                   from "@tanstack/react-query";
import {
  isEvmAddress,
  isIconAddress,
  isIconEoaAddress
}                                   from "@utils/Validator";
import type {Hash as HavahHashType} from "icon-sdk-js/build/types/hash";
import {
  type Address,
  type Hash,
  type Hex
}                                   from "viem";
import type {Chain}                 from "wagmi/chains";
import {z}                          from "zod";



export type Receipt = {
  txHash: Hash | null;
  txLink: string;
  txStatus: TxReceiptStatus;
  txFee: string;
}


export const IconAddress = z.custom<`cx${string}`>();
export type IconAddress = z.infer<typeof IconAddress>;
export const HavahAddress = z.custom<`hx${string}` | IconAddress>();
export type HavahAddress = z.infer<typeof HavahAddress>;

export const EvmAddress = z.custom<Address>();
export type EvmAddress = z.infer<typeof EvmAddress>;

export const AddressTypes = z.custom<EvmAddress | HavahAddress>();
export type AddressTypes = z.infer<typeof AddressTypes>;

export const HashTypes = z.custom<Hash>();
export type HashTypes = z.infer<typeof HashTypes>;

export const HavahHash = z.custom<HavahHashType>();
export type HavahHash = z.infer<typeof HavahHash>;
export const EvmHex = z.custom<Hex>();
export type EvmHex = z.infer<typeof EvmHex>;
export type HexTypes = z.infer<typeof EvmHex | typeof HavahHash>;

export type CaipNetworkId = `${ChainNamespace}:${ChainId}`;
export type ChainId = number;
export type ChainNamespace = 'eip155' | 'solana' | 'polkadot';

export const DefineCaipNetworkInfo = z.object({
  chainId       : z.number(),
  chainNamespace: z.custom<ChainNamespace>(),
  name          : z.string(),
  currency      : z.string(),
  explorerUrl   : z.string(),
  rpcUrl        : z.string(),
  imageUrl      : z.string().optional(),
  imageId       : z.string().optional(),
})
export type DefineCaipNetworkInfo = z.infer<typeof DefineCaipNetworkInfo>;
export type CustomCaipNetwork = Omit<CaipNetwork, "chainId"> & {
  chainId: number
};


export const DefineCommonChainInfo = z.object({
  chainId     : z.custom<Readonly<Chain>["id"]>(),
  chainName   : z.custom<Readonly<Chain>["name"]>(),
  chainTestnet: z.custom<Readonly<Chain>["testnet"]>(),
})
export type DefineCommonChainInfo = z.infer<typeof DefineCommonChainInfo>;


export const DefineCommonInfo      = DefineCommonChainInfo.merge(z.object({
  id    : z.string(),
  name  : z.string(),
  symbol: z.string(),
}))
const DefineEvmContract            = z.object({
  contract     : EvmAddress.refine((val): val is EvmAddress => isEvmAddress(val)),
  devContract  : EvmAddress.refine((val): val is EvmAddress => isEvmAddress(val)),
  stageContract: EvmAddress.refine((val): val is EvmAddress => isEvmAddress(val)),
})
const DefineHavahContract          = z.object({
  contract     : HavahAddress.refine((val): val is HavahAddress => isIconAddress(val)),
  devContract  : HavahAddress.refine((val): val is HavahAddress => isIconAddress(val)),
  stageContract: HavahAddress.refine((val): val is HavahAddress => isIconAddress(val)),
})
export const DefineEvmContractInfo = DefineEvmContract.merge(DefineCommonInfo);
export type DefineEvmContractInfo = z.infer<typeof DefineEvmContractInfo>
export const DefineHavahContractInfo = DefineHavahContract.merge(DefineCommonInfo);
export type DefineHavahContractInfo = z.infer<typeof DefineHavahContractInfo>


export const DefineContractInfo = z.union([DefineEvmContractInfo, DefineHavahContractInfo])
export type DefineContractInfo = z.infer<typeof DefineContractInfo>


export const EvmContractInfo   = DefineCommonInfo.omit({chainTestnet: true}).merge(z.object({
  contract: DefineEvmContract.shape.contract
}))
export const HavahContractInfo = DefineCommonInfo.omit({chainTestnet: true}).merge(z.object({
  contract: DefineHavahContract.shape.contract
}));
export type EvmContractInfo = z.infer<typeof EvmContractInfo>
export type HavahContractInfo = z.infer<typeof HavahContractInfo>

export const ContractInfo = DefineCommonInfo.merge(z.object({
  contract: z.union([EvmAddress.refine((val): val is EvmAddress => isEvmAddress(val))
    , HavahAddress.refine((val): val is HavahAddress => isIconAddress(val))]),
}))
export type ContractInfo = z.infer<typeof ContractInfo>


export const ContractInfos = z.record(ContractInfo)
export type ContractInfos = z.infer<typeof ContractInfos>

export type ContractId<
  contractInfos extends ContractInfos = ContractInfos,
  contractInfoKey extends keyof ContractInfos = keyof ContractInfos,
> = contractInfos[contractInfoKey]["id"]


export const DefineDefaultSwapPair = z.tuple([ContractInfo, z.array(ContractInfo).min(1, {message: "Pair tokens must be one"})])
export type DefineDefaultSwapPair = z.infer<typeof DefineDefaultSwapPair>
export const DefineIndexSwapPair = z.tuple([ContractInfo, z.array(ContractInfo).min(1), z.object({indexPair: z.literal(true)})]);
export type DefineIndexSwapPair = z.infer<typeof DefineIndexSwapPair>


export const DefineSwapPair = z.record(z.union([DefineDefaultSwapPair, DefineIndexSwapPair]));
export type DefineSwapPair = z.infer<typeof DefineSwapPair>

export type TokenPairs = {
                           indexPair: [sourceToken: ContractInfo, destinationTokenListFirstIndex: ContractInfo]
                         } & {
                           [key: ContractId]: DefineDefaultSwapPair
                         }

export type TokensByChain = Readonly<Record<Chain["id"], ContractInfo[]>>
export type TokenPairsByChain = Readonly<Record<Chain["id"], TokenPairs>>
export type BridgesByChain = Readonly<Record<Chain["id"], ContractInfos>>

export type ParamQueryOptions<
  T extends any = any,
  E extends any = any,
  V extends any = any
> = Omit<UseQueryOptions<
  T,
  E,
  V
>, "queryKey" | "queryFn">

export type ParamMutationOptions<
  T extends any = any,
  E extends any = any,
  V extends any = any
> = Omit<UseMutationOptions<
  T,
  E,
  V
>, "mutationKey" | "mutationFn">


export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;

export type AssertionConstType<T> = T[keyof T]

export type AssertionConstListType<T extends readonly any[]> = T[number]

type SingleKeyObject<T> =
  {
    [K in keyof T]
    :
    Record<K, T[K]> & Partial<Record<Exclude<keyof T, K>, never>>;
  }
